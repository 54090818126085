// ** React Import
import { useEffect, useRef } from "react";

// ** Layout Components
import VerticalLayout from "./VerticalLayout";
import HorizontalLayout from "./HorizontalLayout";
import { fetchProfile } from "@store/apps/auth";
import { useDispatch } from "react-redux";
import { getMyRole } from "@store/apps/roles/action";

const Layout = props => {
  // ** Props
  const { hidden, children, settings, saveSettings } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchProfile());
    dispatch(getMyRole());
  }, [dispatch]);

  // ** Ref
  const isCollapsed = useRef(settings.navCollapsed);
  useEffect(() => {
    if (hidden) {
      if (settings.navCollapsed) {
        saveSettings({ ...settings, navCollapsed: false, layout: "vertical" });
        isCollapsed.current = true;
      }
    } else {
      if (isCollapsed.current) {
        saveSettings({ ...settings, navCollapsed: true, layout: settings.lastLayout });
        isCollapsed.current = false;
      } else {
        if (settings.lastLayout !== settings.layout) {
          saveSettings({ ...settings, layout: settings.lastLayout });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hidden]);
  if (settings.layout === "horizontal") {
    return <HorizontalLayout {...props}>{children}</HorizontalLayout>;
  }

  return (
    <VerticalLayout {...props} name='amjad'>
      {children}
    </VerticalLayout>
  );
};

export default Layout;
