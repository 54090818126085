import axios from "axios";
import { analyticsPath } from "@constants/base-urls";

const axiosInstance = axios.create({
  baseURL: analyticsPath,
  withCredentials: true,
  adapter: ["xhr", "http", function myCustomAdapter(config) {}]
});

export const rolesService = {
  createRole: async data => {
    return await axiosInstance.post("/roles", data);
  },

  getRoleByName: async name => {
    return await axiosInstance.get(`/roles/${name}`);
  },

  getMyRole: async () => {
    return await axiosInstance.get("/roles/my");
  },

  deleteRoleByName: async name => {
    return await axiosInstance.delete(`/roles/${name}`);
  },

  updateRoleByName: async (name, data) => {
    return await axiosInstance.patch(`/roles/${name}`, data);
  },

  fetchRolesList: async () => {
    const response = await axiosInstance.get("/roles");
    return response?.data?.data;
  },

  fetchRolesPermissions: async () => {
    return await axiosInstance.get("/roles/permissions");
  }
};

export const ProvidersService = {
  getProviders: async () => {
    const response = await axiosInstance.get("/providers");
    return response.data?.data;
  },

  createProvider: async data => {
    return await axiosInstance.post("/providers", data);
  },

  updateProvider: async (id, data) => {
    return await axiosInstance.patch(`/providers/${id}`, data);
  },

  deleteProvider: async id => {
    return await axiosInstance.delete(`/providers/${id}`);
  }
};

export const PersonnelService = {
  invitePersonnel: async data => {
    return await axiosInstance.post("/personnel/invite", data);
  },

  fetchPersonnelList: async () => {
    const response = await axiosInstance.get("/personnel");
    return response?.data?.data;
  },

  confirmInvite: async code => {
    return await axiosInstance.put(`/personnel/confirm-invite/${encodeURIComponent(code)}`, {});
  },

  updatePerson: async (id, data) => {
    return await axiosInstance.patch(`/personnel/${id}`, data);
  },

  deletePersonnel: async id => {
    return await axiosInstance.delete(`/personnel/${id}`);
  }
};
