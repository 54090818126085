import { createAsyncThunk } from "@reduxjs/toolkit";

import toast from "react-hot-toast";
import {
  ROLES_ADDED_SUCCESS_TITLE,
  ROLE_DELETED_SUCCESS_TITLE,
  ROLES_SUCCESS_TITLE,
  ADDED_PROVIDER_SUCCESS_TITLE,
  UPDATED_PROVIDER_SUCCESS_TITLE,
  DELETED_PROVIDER_SUCCESS_TITLE,
  ADDED_SUCCESS_TITLE,
  ADDED_SUCCESS_TEXT,
  UPDATED_SUCCESS_TITLE,
  UPDATED_SUCCESS_TEXT,
  DELETED_SUCCESS_TITLE,
  DELETED_SUCCESS_TEXT
} from "@constants/text";

import { PersonnelService, ProvidersService, rolesService } from "./service";
import SuccessMessage from "@views/components/toast/SuccessMessage";

// Role Actions
export const createRole = createAsyncThunk("appRoles/createRole", async (data, { rejectWithValue, dispatch }) => {
  try {
    const response = await rolesService.createRole(data);

    toast.success(ROLES_ADDED_SUCCESS_TITLE, { duration: 2000 });

    return { data, ...response.data };
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getRoleByName = createAsyncThunk("appRoles/getRoleByName", async (name, { rejectWithValue }) => {
  try {
    return await rolesService.getRoleByName(name);
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getMyRole = createAsyncThunk("appRoles/getMyRole", async (_, { rejectWithValue }) => {
  try {
    return await rolesService.getMyRole();
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const deleteRoleByName = createAsyncThunk("appRoles/deleteRoleByName", async (name, { rejectWithValue }) => {
  try {
    await rolesService.deleteRoleByName(name);

    toast.success(ROLE_DELETED_SUCCESS_TITLE, { duration: 2000 });

    return name;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateRoleByName = createAsyncThunk(
  "appRoles/updateRoleByName",
  async ({ data, name }, { rejectWithValue, dispatch }) => {
    try {
      const response = await rolesService.updateRoleByName(name, data);

      dispatch(fetchRolesList());

      toast.success(ROLES_SUCCESS_TITLE, { duration: 2000 });

      return response?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchRolesList = createAsyncThunk("appRoles/fetchRolesList", async (_, { rejectWithValue }) => {
  try {
    return await rolesService.fetchRolesList();
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const fetchRolesPermissions = createAsyncThunk(
  "appRoles/fetchRolesPermissions",
  async (_, { rejectWithValue }) => {
    try {
      return await rolesService.fetchRolesPermissions();
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Providers Actions
export const getProviders = createAsyncThunk("appAnalytics/getProviders", async (_, { rejectWithValue }) => {
  try {
    return await ProvidersService.getProviders();
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const createProvider = createAsyncThunk("appAnalytics/createProvider", async (data, { rejectWithValue }) => {
  try {
    const response = await ProvidersService.createProvider(data);

    toast(t => <SuccessMessage close={() => toast.dismiss(t.id)} title={ADDED_PROVIDER_SUCCESS_TITLE} />);

    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateProvider = createAsyncThunk(
  "appAnalytics/updateProvider",
  async ({ id, data }, { rejectWithValue, dispatch }) => {
    try {
      const response = await ProvidersService.updateProvider(id, data);

      dispatch(getProviders());

      toast(t => <SuccessMessage close={() => toast.dismiss(t.id)} title={UPDATED_PROVIDER_SUCCESS_TITLE} />);

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteProvider = createAsyncThunk("appAnalytics/deleteProvider", async (id, { rejectWithValue }) => {
  try {
    await ProvidersService.deleteProvider(id);

    toast(t => <SuccessMessage close={() => toast.dismiss(t.id)} title={DELETED_PROVIDER_SUCCESS_TITLE} />);

    return id;
  } catch (error) {
    return rejectWithValue(error);
  }
});

// Personnel Actions
export const invitePersonnel = createAsyncThunk(
  "appPersonnel/invitePersonnel",
  async ({ email, role, position, reportOrder }, { rejectWithValue }) => {
    try {
      const response = await PersonnelService.invitePersonnel({
        email: email?.toLowerCase(),
        role,
        position,
        reportOrder
      });

      toast(t => (
        <SuccessMessage close={() => toast.dismiss(t.id)} title={ADDED_SUCCESS_TITLE} text={ADDED_SUCCESS_TEXT} />
      ));

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchPersonnelList = createAsyncThunk(
  "appPersonnel/fetchPersonnelList",
  async (_, { rejectWithValue }) => {
    try {
      return await PersonnelService.fetchPersonnelList();
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const confirmInvite = createAsyncThunk("appPersonnel/confirmInvite", async (code, { rejectWithValue }) => {
  try {
    await PersonnelService.confirmInvite(code);
    window.location.replace("/account-settings/account/");
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updatePerson = createAsyncThunk(
  "appPersonnel/updatePerson",
  async ({ formData, id, myRole, myEmail }, { rejectWithValue }) => {
    try {
      const data =
        myEmail === formData.email
          ? {
              active: formData?.active,
              position: formData?.position,
              ...(formData.role !== myRole?.roleName ? { role: formData.role } : {})
            }
          : formData;

      const response = await PersonnelService.updatePerson(id, data);

      toast(t => (
        <SuccessMessage close={() => toast.dismiss(t.id)} title={UPDATED_SUCCESS_TITLE} text={UPDATED_SUCCESS_TEXT} />
      ));

      return response?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deletePersonnel = createAsyncThunk("appRoles/deleteRole", async (id, { rejectWithValue }) => {
  try {
    await PersonnelService.deletePersonnel(id);

    toast(t => (
      <SuccessMessage close={() => toast.dismiss(t.id)} title={DELETED_SUCCESS_TITLE} text={DELETED_SUCCESS_TEXT} />
    ));

    return id;
  } catch (error) {
    return rejectWithValue(error);
  }
});
